import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import styles from './InputControlLabel.module.scss';
import { colorSchemes } from '../constants';

export const inputControlLableColorSchemes = { ...colorSchemes };

export default function InputControlLabel({
    text,
    colorScheme = inputControlLableColorSchemes.LIGHT,
}) {
    return <h3 className={classNames(styles.label, styles[colorScheme])}>{text}</h3>;
}

InputControlLabel.propTypes = {
    text: PropTypes.string.isRequired,
    colorScheme: PropTypes.oneOf(Object.values(inputControlLableColorSchemes)),
};
