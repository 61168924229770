import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import styles from './InputHelper.module.scss';
import { colorSchemes } from '../constants';

export const inputHelperColorSchemes = { ...colorSchemes };

export default function InputHelper({ text, colorScheme = inputHelperColorSchemes.LIGHT }) {
    return <p className={classNames(styles.helper, styles[colorScheme])}>{text}</p>;
}

InputHelper.propTypes = {
    text: PropTypes.string.isRequired,
    colorScheme: PropTypes.oneOf(Object.values(inputHelperColorSchemes)),
};
