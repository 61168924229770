import axios from 'axios';

const baseUrl = '/invitations';

export async function createInvitations(invitationContextId, emailAddresses, guest = false) {
    const response = await axios.post(baseUrl, {
        invitation_context_id: invitationContextId,
        email_addresses: emailAddresses,
        guest,
    });
    return response.data;
}

export async function submitAcceptInvitation(
    token,
    firstName,
    lastName,
    password,
    confirmPassword,
) {
    const response = await axios.post(`${baseUrl}/accept_invitation`, {
        token,
        first_name: firstName,
        last_name: lastName,
        password,
        password_confirmation: confirmPassword,
    });
    return response.data;
}

export async function submitAcceptUserInvitation(token, password) {
    const response = await axios.post(`${baseUrl}/accept_user_invitation`, {
        token,
        password,
    });
    return response.data;
}

export async function deleteInvitation(invitationId) {
    const response = await axios.delete(`${baseUrl}/${invitationId}`);
    return response.data;
}

export async function resendInvitation(invitationId) {
    const response = await axios.post(`${baseUrl}/resend_invitation`, {
        id: invitationId,
    });
    return response.data;
}
