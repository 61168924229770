import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import styles from './StatusMessage.module.scss';

export const statusTypes = {
    SUCCESS: 'success',
    ERROR: 'error',
};

export const statusTightness = {
    TIGHT: 'tight',
    LOOSE: 'loose',
};

export default function StatusMessage({
    message,
    status = statusTypes.ERROR,
    tightness = statusTightness.LOOSE,
}) {
    return (
        <p className={classNames(styles.statusMessage, styles[status], styles[tightness])}>
            {message}
        </p>
    );
}

StatusMessage.propTypes = {
    status: PropTypes.oneOf(Object.values(statusTypes)),
    message: PropTypes.string.isRequired,
    tightness: PropTypes.oneOf(Object.values(statusTightness)),
};
